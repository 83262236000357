exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-links-tsx": () => import("./../../../src/pages/about/links.tsx" /* webpackChunkName: "component---src-pages-about-links-tsx" */),
  "component---src-pages-about-portfolio-tsx": () => import("./../../../src/pages/about/portfolio.tsx" /* webpackChunkName: "component---src-pages-about-portfolio-tsx" */),
  "component---src-pages-about-uses-mdx": () => import("./../../../src/pages/about/uses.mdx" /* webpackChunkName: "component---src-pages-about-uses-mdx" */),
  "component---src-pages-awesomes-tsx": () => import("./../../../src/pages/awesomes.tsx" /* webpackChunkName: "component---src-pages-awesomes-tsx" */),
  "component---src-pages-category-name-tsx": () => import("./../../../src/pages/{Category.name}.tsx" /* webpackChunkName: "component---src-pages-category-name-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notice-mdx": () => import("./../../../src/pages/legal-notice.mdx" /* webpackChunkName: "component---src-pages-legal-notice-mdx" */),
  "component---src-pages-lib-generative-tsx": () => import("./../../../src/pages/lib/generative.tsx" /* webpackChunkName: "component---src-pages-lib-generative-tsx" */),
  "component---src-pages-lib-index-mdx": () => import("./../../../src/pages/lib/index.mdx" /* webpackChunkName: "component---src-pages-lib-index-mdx" */),
  "component---src-pages-lib-inspiration-tsx": () => import("./../../../src/pages/lib/inspiration.tsx" /* webpackChunkName: "component---src-pages-lib-inspiration-tsx" */),
  "component---src-pages-lib-mixtapes-mdx": () => import("./../../../src/pages/lib/mixtapes.mdx" /* webpackChunkName: "component---src-pages-lib-mixtapes-mdx" */),
  "component---src-pages-lib-weeklymood-tsx": () => import("./../../../src/pages/lib/weeklymood.tsx" /* webpackChunkName: "component---src-pages-lib-weeklymood-tsx" */),
  "component---src-pages-lib-wishlist-tsx": () => import("./../../../src/pages/lib/wishlist.tsx" /* webpackChunkName: "component---src-pages-lib-wishlist-tsx" */),
  "component---src-pages-notebook-tsx": () => import("./../../../src/pages/notebook.tsx" /* webpackChunkName: "component---src-pages-notebook-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-awesome-tsx-content-file-path-content-awesome-java-index-mdx": () => import("./../../../src/templates/awesome.tsx?__contentFilePath=/home/runner/work/website/website/content/awesome/java/index.mdx" /* webpackChunkName: "component---src-templates-awesome-tsx-content-file-path-content-awesome-java-index-mdx" */),
  "component---src-templates-awesome-tsx-content-file-path-content-awesome-react-native-index-mdx": () => import("./../../../src/templates/awesome.tsx?__contentFilePath=/home/runner/work/website/website/content/awesome/react-native/index.mdx" /* webpackChunkName: "component---src-templates-awesome-tsx-content-file-path-content-awesome-react-native-index-mdx" */),
  "component---src-templates-garden-tsx-content-file-path-content-garden-2015-01-12-gbc-choke-repair-index-mdx": () => import("./../../../src/templates/garden.tsx?__contentFilePath=/home/runner/work/website/website/content/garden/2015-01-12--gbc-choke-repair/index.mdx" /* webpackChunkName: "component---src-templates-garden-tsx-content-file-path-content-garden-2015-01-12-gbc-choke-repair-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-aefct-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/aefct/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-aefct-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-costalanparty-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/costalanparty/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-costalanparty-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-fragmotion-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/fragmotion/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-fragmotion-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-hommad-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/hommad/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-hommad-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-lojasistemas-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/lojasistemas/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-lojasistemas-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-mooda-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/mooda/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-mooda-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-nuba-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/nuba/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-nuba-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-paginasamarelas-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/paginasamarelas/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-paginasamarelas-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-portfolio-portfolio-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/runner/work/website/website/content/portfolio/portfolio/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-portfolio-portfolio-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-expectativas-e-ambicoes-preliminares-para-2020-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2018-11-28--expectativas-e-ambicoes-preliminares-para-2020/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-expectativas-e-ambicoes-preliminares-para-2020-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-expectativas-e-ambicoes-preliminares-para-2020-index-pt-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2018-11-28--expectativas-e-ambicoes-preliminares-para-2020/index.pt.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-expectativas-e-ambicoes-preliminares-para-2020-index-pt-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-o-ambiente-em-2050-a-importancia-de-uma-visao-consciente-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2018-11-28--o-ambiente-em-2050-a-importancia-de-uma-visao-consciente/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2018-11-28-o-ambiente-em-2050-a-importancia-de-uma-visao-consciente-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2019-04-08-a-importancia-de-infrastruturas-verdes-no-desenvolvimento-urbano-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2019-04-08--a-importancia-de-infrastruturas-verdes-no-desenvolvimento-urbano/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2019-04-08-a-importancia-de-infrastruturas-verdes-no-desenvolvimento-urbano-index-mdx" */),
  "component---src-templates-prose-tsx-content-file-path-content-writing-2020-10-01-covid-19-air-pollution-portugal-index-mdx": () => import("./../../../src/templates/prose.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2020-10-01--covid-19-air-pollution-portugal/index.mdx" /* webpackChunkName: "component---src-templates-prose-tsx-content-file-path-content-writing-2020-10-01-covid-19-air-pollution-portugal-index-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2021-11-29-crash-game-index-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2021-11-29--crash-game/index.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2021-11-29-crash-game-index-mdx" */),
  "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-15-activity-recognition-sensory-data-index-mdx": () => import("./../../../src/templates/tutorial.tsx?__contentFilePath=/home/runner/work/website/website/content/writing/2022-01-15--activity-recognition-sensory-data/index.mdx" /* webpackChunkName: "component---src-templates-tutorial-tsx-content-file-path-content-writing-2022-01-15-activity-recognition-sensory-data-index-mdx" */)
}

